<template>
  <div>
    <h4>
      Servicebeurten
    </h4>
    <b-table v-if="services.length" :items="services" :fields="fields">
      <template v-slot:cell(DateTimeServiceEnded)="data">
        <div class="form-group">
          {{ formatDate(data.item.DateTimeServiceEnded) }}
        </div>
      </template>

      <template v-slot:cell(DateTimeScheduledServiceStart)="data">
        <div class="form-group">
          {{ formatDate(data.item.DateTimeServiceStart) }}
        </div>
      </template>
    </b-table>

    <div v-else class="text-center w-100 mt-5 mb-5">
      <h4 class="text-secondary">Geen servicebeurten gevonden</h4>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'

import { getServicesBySerialID } from '@/services/CustomerServicesService.js'

import dayjs from 'dayjs'

export default {
  components: {
    BTable
  },
  props: {
    serialid: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      services: [],
      fields: [
        {
          key: 'Description',
          label: 'Omschrijving'
        },
        {
          key: 'DateTimeScheduledServiceStart',
          label: 'Ingeplande service datum'
        },
        {
          key: 'DateTimeServiceEnded',
          label: 'Service voltooid op'
        },

        {
          key: 'Status',
          label: 'Huidige opvolgstatus'
        }
      ]
    }
  },
  async created() {
    const result = await getServicesBySerialID({
      customerID: this.$store.state.customerID,
      serialID: this.serialid
    })

    this.services = result.Collection
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('DD-MM-YYYY')
    }
  }
}
</script>

<style></style>
