<template>
  <div>
    <h4>
      Bestanden
    </h4>

    <b-table
      v-if="attachments.length"
      class="table"
      :items="attachments"
    ></b-table>

    <div v-else class="text-center w-100 mt-5 mb-5">
      <h4 class="text-secondary">Geen bestanden gevonden</h4>
    </div>
  </div>
</template>

<script>
import { getSerialAttachments } from '@/services/CustomerSerialService'
import { BTable } from 'bootstrap-vue'
export default {
  components: {
    BTable
  },
  props: {
    serialid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      attachments: []
    }
  },
  async created() {
    this.attachments = await getSerialAttachments({
      serialID: this.serialid,
      customerID: this.$store.state.customerID
    })
  }
}
</script>

<style></style>
