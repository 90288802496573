<template>
  <div>
    <b-modal
      :id="`modal-${serialid}`"
      hide-footer
      :title="`Serienummer ${serialid} aanpassen`"
    >
      <b-form @submit="onSubmit">
        <b-form-group
          id="serialidlabel"
          label="Serienummer"
          label-for="serialid"
        >
          <b-form-input
            id="serialid"
            v-model="form.serialid"
            type="text"
            disabled
            placeholder="Voer het serienummer in"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="itemidlabel" label="Artikel" label-for="itemid">
          <b-form-input
            id="itemid"
            v-model="form.itemid"
            :options="items"
            required
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="manufacturerSerialIDlabel"
          label="Serienummer Leverancier"
          label-for="manufacturerSerialID"
        >
          <b-form-input
            id="manufacturerSerialID"
            v-model="form.manufacturerserial"
            type="text"
            placeholder="Voer het serienummer in van de leverancier"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="serialCalibrationDateLabel"
          label="Kalibratie datum"
          label-for="serialCalibrationDate"
        >
          <b-form-input
            id="serialCalibrationDate"
            v-model="form.calibrationDate"
            type="date"
            placeholder="Voer de kalibratie datum in van het serienummer"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="notesLabel" label="Opmerking" label-for="notes">
          <b-form-textarea
            id="notes"
            v-model="form.notes"
            placeholder="Geef eventueel een opmerking op"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <div class="w-100 mt-2">
          <b-button type="submit" variant="primary"
            >Serienummer bijwerken</b-button
          >
          <b-button
            variant="secondary"
            class="float-right"
            @click="hideModal()"
          >
            Sluiten
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { updateSerial } from '@/services/CustomerSerialService'

import {
  BModal,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea
} from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea
  },
  props: {
    serialid: {
      required: true,
      type: String,
      default: ''
    },
    itemid: {
      required: true,
      type: String,
      default: ''
    },
    manufacturerserial: {
      required: false,
      type: String,
      default: ''
    },
    notes: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        serialid: '',
        itemid: '',
        manufacturerserial: '',
        notes: '',
        calibrationDate: 'calibrationDate'
      },
      items: [{ text: 'Selecteer het bijbehorende artikel', value: null }],
      show: true
    }
  },
  created: function() {
    this.form = {
      serialid: this.serialid,
      itemid: this.itemid,
      notes: this.notes,
      manufacturerserial: this.manufacturerserial,
      calibrationDate: this.calibrationDate
    }
  },
  watch: {
    serialid() {
      if (this.serialid === '') return
      this.form = {
        serialid: this.serialid,
        itemid: this.itemid,
        notes: this.notes,
        manufacturerserial: this.manufacturerserial,
        calibrationDate: this.calibrationDate
      }
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show(`modal-${this.serialid}`)
    },
    hideModal() {
      this.$bvModal.hide(`modal-${this.serialid}`)
    },
    async onSubmit(event) {
      event.preventDefault()

      const result = await updateSerial({
        SerialID: this.form.serialid,
        ItemID: this.form.itemid,
        Notes: this.form.notes,
        customerID: this.$store.state.customerID,
        ManufacturerSerial: this.form.manufacturerserial,
        CalibrationDate: this.form.calibrationDate
      })

      if (result.SerialID === this.form.serialid) {
        this.$emit('serial-updated')
        this.hideModal()
        this.$toasted.show(`Serienummer ${result.SerialID} is aangepast`)
        return
      }

      this.$toasted.show(`Er is iets mis gegaan, probeer het later opnieuw`)
    }
  }
}
</script>
