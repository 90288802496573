const { axiosCreate } = require('./Api')
const { checkResponse } = require('../controllers/errorHandler')
import store from '../state/store'

/**
 * Create a new serial for a customer
 * @param {string} serialid ID of the new serial
 * @param {string} itemid Item in question
 * @param {string} comment
 * @param {string} customerID
 * @returns Array containing all found serial records
 */
async function addSerial({
  SerialID,
  ItemID,
  customerID,
  ManufacturerSerial,
  Notes,calibrationDate
}) {
  const result = await axiosCreate.post(
    `/shop/customer/${customerID}/customeritems/${ItemID}/serials`,
    {
      CustomerID: customerID,
      SerialID,
      ItemID,
      Notes,
      ManufacturerSerial,
      WarehouseID: 'Rotterdam',
      calibrationDate
    },
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data
}

/**
 * Update customer serial
 * @param {string} serialid ID of the new serial
 * @param {string} itemid Item in question
 * @param {string} Notes Notes on a serial
 * @param {string} customerID Related customer ID
 * @returns
 */
async function updateSerial({
  SerialID,
  ItemID,
  customerID,
  ManufacturerSerial,
  Notes,
  CalibrationDate
}) {
  const result = await axiosCreate.patch(
    `/shop/customer/${customerID}/customeritems/${ItemID}/serials/${SerialID}`,
    {
      Notes,
      ManufacturerSerial,
      CalibrationDate
    },
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data
}

/**
 * Update customer serial
 * @param {string} SerialID ID of the new serial
 * @param {string} ItemID Item in question
 * @param {string} customerID Related customer ID
 * @returns {object} Server response
 */
async function deleteSerial({ SerialID, customerID, ItemID }) {
  const result = await axiosCreate.delete(
    `/shop/customer/${customerID}/customeritems/${ItemID}/serials/${SerialID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data
}

/**
 * Get Customer's own serials
 * @param {string} customerID
 * @returns Array containing all found serial records
 */
async function getSerials({ customerID }) {
  const result = await axiosCreate.get(
    `/shop/customer/${customerID}/customeritemserials`,
    {
      headers: { authorization: store.getters.token },
      params: {
        warehouse: 'Rotterdam'
      }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data.Collection
}

/**
 * Get Customer's rented serials
 * @param {string} customerID
 * @returns Array containing all found serial records
 */
async function getRentedSerials({ customerID }) {
  const result = await axiosCreate.get(
    `/shop/customer/${customerID}/renteditems/serials`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data.Collection
}

/**
 * Get Customer's rented serial
 * @param {string} customerID
 * @param {string} serialID
 * @returns Array containing all found serial records
 */
async function getRentedSerial({ customerID, serialID }) {
  const result = await axiosCreate.get(
    `/shop/customer/${customerID}/renteditems/serials/${serialID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return

  return result.data
}

/**
 * Get attachments by serialID
 * @param {string} serialID Serial ID of which you want to receive attachments
 * @param {string} customerID The customer ID you're logged in with
 * @returns {Array} Attachment list
 */
async function getSerialAttachments({ serialID, customerID }) {
  const result = await axiosCreate.get(
    `/shop/customer/${customerID}/renteditems/serials/${serialID}/attachments`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  return result.data.Collection
}

module.exports = {
  getSerials,
  getRentedSerial,
  getRentedSerials,
  addSerial,
  getSerialAttachments,
  updateSerial,
  deleteSerial
}
