const { axiosCreate } = require('./Api')
const { checkResponse } = require('../controllers/errorHandler')

import store from '../state/store'

async function getServicesBySerialID({ customerID, serialID } = {}) {
  const result = await axiosCreate.get(
    `shop/customer/${customerID}/services/${serialID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )

  if ((await checkResponse(result)) !== null) return

  return result.data
}

async function getServices({ customerID }) {
  const result = await axiosCreate.get(`shop/customer/${customerID}/services`, {
    headers: { authorization: store.getters.token }
  })

  return result.data.Collection
}

module.exports = { getServicesBySerialID, getServices }
