<template>
  <div>
    <b-button v-b-modal.newserial class="mb-3" @click="showModal"
      >Nieuw serienummer aanmaken</b-button
    >

    <b-modal id="newserial" title="Nieuw serienummer toevoegen" hide-footer>
      <b-form @submit="onSubmit">
        <b-form-group
          id="serialidlabel"
          label="Serienummer"
          label-for="serialid"
        >
          <b-form-input
            id="serialid"
            v-model="form.serialid"
            type="text"
            placeholder="Voer het serienummer in"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="manufacturerSerialIDlabel"
          label="Serienummer Leverancier"
          label-for="manufacturerSerialID"
        >
          <b-form-input
            id="manufacturerSerialID"
            v-model="form.manufacturerSerial"
            type="text"
            placeholder="Voer het serienummer in van de leverancier"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="serialCalibrationDateLabel"
          label="Kalibratie datum"
          label-for="serialCalibrationDate"
        >
          <b-form-input
            id="serialCalibrationDate"
            v-model="form.calibrationDate"
            type="date"
            placeholder="Voer de kalibratie datum in van het serienummer"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="itemidlabel" label="Artikel" label-for="itemid">
          <b-form-select
            id="itemid"
            v-model="form.itemid"
            text-field="Description"
            value-field="ItemID"
            :options="items"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group id="noteslabel" label="Opmerking" label-for="notes">
          <b-form-textarea
            id="notes"
            v-model="form.notes"
            placeholder="Geef eventueel een opmerking op"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <div class="w-100 mt-2">
          <b-button type="submit" variant="primary"
            >Serienummer aanmaken</b-button
          >
          <b-button
            variant="secondary"
            class="float-right"
            @click="hideModal()"
          >
            Sluiten
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { addSerial } from '@/services/CustomerSerialService'
import { getItems } from '@/services/CustomerItemService'

import {
  BModal,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea
} from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea
  },
  data() {
    return {
      form: {
        serialid: '',
        itemid: '',
        notes: '',
        manufacturerSerial: ''
      },
      items: [{ text: 'Selecteer het bijbehorende artikel', value: null }],
      show: true
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide(`newserial`)
    },
    async showModal() {
      this.$bvModal.show(`newserial`)
      this.items = await getItems({ customerID: this.$store.state.customerID })
    },
    async onSubmit(event) {
      event.preventDefault()

      const result = await addSerial({
        SerialID: this.form.serialid,
        ItemID: this.form.itemid,
        Notes: this.form.notes,
        customerID: this.$store.state.customerID,
        ManufacturerSerial: this.form.manufacturerSerial,
        calibrationDate: this.form.calibrationDate
      })

      if (result.SerialID === this.form.serialid) {
        this.form.serialid = ''
        this.form.itemid = ''
        this.form.notes = ''
        this.form.manufacturerSerial = ''
        this.$emit('serial-added')
        this.hideModal()
        this.$toasted.show(`Serienummer ${result.SerialID} is aangemaakt`)
        return
      }

      this.$toasted.show(`Er is iets mis gegaan, probeer het later opnieuw`)
    }
  }
}
</script>
