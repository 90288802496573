<template>
  <div>
    <h4>
      Artikel gegevens
    </h4>
    <table class="table" v-if="item.Description">
      <tr>
        <td><strong>Artikelnummer:</strong></td>
        <td>{{ item.ItemID }}</td>
      </tr>
      <tr>
        <td><strong>Omschrijving:</strong></td>
        <td>{{ item.Description }}</td>
      </tr>

    </table>

    <div v-html="item.MediumDescription" />
    <div v-html="item.LongDescription" />
  </div>
</template>

<script>
import { getItem } from '@/services/CustomerItemService'
export default {
  props: {
    itemid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      item: {}
    }
  },
  async created() {
    this.item = await getItem({
      customerID: this.$store.state.customerID,
      itemID: this.itemid
    })
  }
}
</script>

<style></style>
