var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Mijn serienummers")]),_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend metus enim, eu tristique dui sollicitudin sit amet. Sed interdum, felis quis tincidunt condimentum, nisl elit tempor justo, dictum pulvinar velit metus eu eros. ")]),_c('serials-modal-create',{on:{"serial-added":function($event){return _vm.getSerials()}}}),_c('serials-modal-edit',{ref:"modifySerialModal",staticClass:"mr-2",attrs:{"serialid":_vm.modifyModal.serialID,"itemid":_vm.modifyModal.itemID,"manufacturerserial":_vm.modifyModal.manufacturerSerial,"notes":_vm.modifyModal.notes},on:{"serial-updated":function($event){return _vm.getSerials()}}}),(_vm.serials.length > 0)?_c('b-table',{attrs:{"items":_vm.serials,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"cell(CalibrationDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.CalibrationDate))+" ")]}},{key:"cell(ReportID)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mb-3 mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.openModifySerial({
              serialID: data.item.SerialID,
              itemID: data.item.ItemID.Key,
              manufacturerSerial: data.item.ManufacturerSerial,
              notes: data.item.Notes
            })}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1),_c('serials-modal-info',{attrs:{"serialid":data.item.SerialID,"serial":data.item}}),_c('b-button',{staticClass:"mb-3 ml-2",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.$bvModal.show(("deleteSerial" + (data.item.SerialID)))}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1),_c('b-modal',{attrs:{"id":("deleteSerial" + (data.item.SerialID)),"title":"Weet je het zeker?","hide-footer":"","hide-header":""}},[_c('div',{staticClass:"pb-4"},[_c('h5',[_vm._v("Opgelet!")]),_c('strong',[_vm._v(" Weet je zeker dat je serienummer "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(data.item.SerialID)+" ("+_vm._s(data.item.ItemID.Description)+")")]),_vm._v(" wilt verwijderen? ")])]),_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.deleteSerial({
                  ItemID: data.item.ItemID.Key,
                  SerialID: data.item.SerialID
                })}}},[_vm._v("Verwijderen")]),_c('b-button',{on:{"click":function($event){return _vm.$bvModal.hide(("deleteSerial" + (data.item.SerialID)))}}},[_vm._v("Annuleren")])],1)])],1)]}}],null,false,431751785)}):_c('p',[_vm._v(" U heeft nog geen serienummers om te bekijken. Wanneer u een nieuw serienummer aanmaakt zal deze hier worden getoond. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }