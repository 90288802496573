<template>
  <div>
    <h3>Mijn serienummers</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend
      metus enim, eu tristique dui sollicitudin sit amet. Sed interdum, felis
      quis tincidunt condimentum, nisl elit tempor justo, dictum pulvinar velit
      metus eu eros.
    </p>

    <serials-modal-create @serial-added="getSerials()" />

    <serials-modal-edit
      ref="modifySerialModal"
      class="mr-2"
      :serialid="modifyModal.serialID"
      :itemid="modifyModal.itemID"
      :manufacturerserial="modifyModal.manufacturerSerial"
      :notes="modifyModal.notes"
      @serial-updated="getSerials()"
    />

    <b-table
      v-if="serials.length > 0"
      :items="serials"
      :fields="fields"
      responsive
    >
      <template v-slot:cell(CalibrationDate)="data">
        {{ formatDate(data.item.CalibrationDate)}}
      </template>
      <template v-slot:cell(ReportID)="data">
        <div class="d-flex">
          <b-button
            size="sm"
            class="mb-3 mr-2"
            @click="
              openModifySerial({
                serialID: data.item.SerialID,
                itemID: data.item.ItemID.Key,
                manufacturerSerial: data.item.ManufacturerSerial,
                notes: data.item.Notes
              })
            "
            ><font-awesome-icon icon="edit"
          /></b-button>

          <serials-modal-info
            :serialid="data.item.SerialID"
            :serial="data.item"
          />
          <b-button
            size="sm"
            variant="danger"
            class="mb-3 ml-2"
            @click="$bvModal.show(`deleteSerial${data.item.SerialID}`)"
          >
            <font-awesome-icon icon="trash" />
          </b-button>
          <b-modal
            :id="`deleteSerial${data.item.SerialID}`"
            title="Weet je het zeker?"
            hide-footer
            hide-header
          >
            <div class="pb-4">
              <h5>Opgelet!</h5>
              <strong>
                Weet je zeker dat je serienummer
                <span class="text-primary"
                  >{{ data.item.SerialID }} ({{
                    data.item.ItemID.Description
                  }})</span
                >
                wilt verwijderen?
              </strong>
            </div>
            <div class="w-100">
              <b-button
                variant="primary"
                class="mr-2"
                @click="
                  deleteSerial({
                    ItemID: data.item.ItemID.Key,
                    SerialID: data.item.SerialID
                  })
                "
                >Verwijderen</b-button
              >
              <b-button
                @click="$bvModal.hide(`deleteSerial${data.item.SerialID}`)"
                >Annuleren</b-button
              >
            </div>
          </b-modal>
        </div>
      </template>
    </b-table>
    <p v-else>
      U heeft nog geen serienummers om te bekijken. Wanneer u een nieuw
      serienummer aanmaakt zal deze hier worden getoond.
    </p>
  </div>
</template>

<script>
import { getSerials, deleteSerial } from '@/services/CustomerSerialService.js'

import SerialsModalCreate from './SerialsModalCreate.vue'
import SerialsModalEdit from './SerialsModalEdit.vue'
import SerialsModalInfo from './SerialsModalInfo.vue'
import { BTable, BButton } from 'bootstrap-vue'
import dayjs from "dayjs"

export default {
  name: 'CustomerSerial',
  components: {
    BTable,
    SerialsModalCreate,
    SerialsModalEdit,
    SerialsModalInfo,
    BButton
  },
  data() {
    return {
      reservations: [],
      serials: [],
      fields: [
        {
          key: 'SerialID',
          label: 'Serienummer'
        },
        {
          key: 'ItemID.Description',
          label: 'Artikelnummer'
        },
        {
          key: 'ManufacturerSerial',
          label: 'Serienummer Leverancier'
        },
        {
          key: 'CalibrationDate',
          label: 'Laatste service datum'
        },
        {
          key: 'ReportID',
          label: 'Acties'
        }
      ],
      modifyModal: {
        serialID: '',
        itemID: '',
        manufacturerSerial: '',
        notes: ''
      }
    }
  },
  created: async function() {
    this.getSerials()
    this.$store.commit('setReservations', { reservations: this.reservations })
  },
  methods: {
    async getSerials() {
      this.serials = await getSerials({
        customerID: this.$store.state.customerID
      })
    },
    async deleteSerial({ SerialID, ItemID }) {
      this.$bvModal.hide(`deleteSerial${SerialID}`)
      await deleteSerial({
        SerialID,
        customerID: this.$store.state.customerID,
        ItemID
      })

      this.$toasted.show(`Serienummer ${SerialID} is verwijderd`)

      this.getSerials()
    },
    formatDate(date) {
      if (date === null) return 'Onbekend'
      return dayjs(date).format("DD-MM-YYYY")
    },
    async openModifySerial({ serialID, itemID, manufacturerSerial, notes }) {
      this.modifyModal.serialID = serialID
      this.modifyModal.itemID = itemID
      this.modifyModal.manufacturerSerial = manufacturerSerial
      this.modifyModal.notes = notes

      this.$refs.modifySerialModal.showModal()
    }
  }
}
</script>

<style scss></style>
