<template>
  <div>
    <h4>
      Serienummer gegevens
    </h4>
    <table class="table">
      <tr>
        <td><strong>Serienummer:</strong></td>
        <td>{{ serial.SerialID }}</td>
      </tr>
      <tr>
        <td><strong>Huurperiode:</strong></td>
        <td>{{ rentalPeriod }}</td>
      </tr>
      <tr>
        <td><strong>Serienummer leverancier:</strong></td>
        <td>{{ serial.ManufacturerSerial || 'Onbekend' }}</td>
      </tr>
      <tr>
        <td><strong>Kalibratie datum:</strong></td>
        <td>{{ formatDate(serial.CalibrationDate)}}</td>
      </tr>
      <tr><td><strong>Opmerking</strong></td><td>{{serial.Notes}}</td></tr>

    </table>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    serial: {
      required: true,
      type: Object
    }
  },
  computed: {
    rentalPeriod() {
      const startDate = dayjs(this.serial.DateTimeBusinessStart).format(
        'DD-MM-YYYY'
      )
      let endDate = dayjs(this.serial.DateTimeBusinessEnd).format(
        'DD-MM-YYYY'
      )
      if (!this.serial.DateTimeBusinessEnd) {
        endDate = 'Niet bepaald'
      }

      return `${startDate} T/M ${endDate}`
    }
  },
  methods: {
    formatDate(date) {
      if (date === null) return "Onbekend"
      return dayjs(this.serial.CalibrationDate).format("DD-MM-YYYY")
    }
  },
}
</script>

<style></style>
